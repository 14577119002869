import React from "react";

import DefaultLayout from "../../layouts/default";

const Impressum = () => (
  <DefaultLayout title="Datenschutz">
    <div className="white portrait">
      <div className="navContentSpacerHelperSingleNavBar"></div>
      <header className="navWrapper">
        <nav className="mainNav singleNavBar">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <h3 className="has-padding-top smallStaticPadding-bottom">
            Impressum WigaSoft
          </h3>
          <p>
            <h5 className="padding-10-bottom">Verantwortung</h5>
            WigaSoft AG<br />
            Oberstrasse 222<br />
            9014 St. Gallen<br /><br />
            info@wigasoft.ch
          </p>
          <br /><br />
          <p>
            <h5 className="padding-10-bottom">Konzept und Design</h5>
            GoEast GmbH<br />
            Oberstrasse 222<br />
            9014 St. Gallen<br /><br />
          </p>
          <br /><br />
          <p>
            <h5 className="padding-10-bottom">Haftungsausschluss</h5>
            Alle Angaben auf unserer Website wurden sorgfältig geprüft. Wir sind bemüht, dafür Sorge zu tragen, dass die von uns bereitgestellten Informationen aktuell, richtig und vollständig sind. Dennoch ist das Auftreten von Fehlern nicht völlig auszuschliessen, so dass wir für die Vollständigkeit, Richtigkeit und Aktualität der Informationen, auch journalistisch-redaktioneller Art, keine Gewähr übernehmen können. Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen.<br />
            <br />
            Der Herausgeber kann Texte nach eigenem Ermessen und ohne vorherige Ankündigung ändern oder löschen und ist nicht dazu verpflichtet, die Inhalte dieser Website zu aktualisieren. Die Nutzung dieser Website bzw. der Zugang zu ihr erfolgt auf eigenes Risiko des Besuchers. Der Herausgeber, seine Kunden oder Partner sind nicht verantwortlich für Schäden, wie z.B. direkte, indirekte, zufällige oder Folgeschäden, die angeblich durch den Besuch dieser Website verursacht wurden und übernehmen folglich keine Haftung für solche Schäden.<br />
            <br />
            Der Herausgeber übernimmt auch keine Verantwortung oder Haftung für den Inhalt und die Verfügbarkeit von Websites Dritter, die über externe Links von dieser Website aus erreicht werden können. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert sich daher ausdrücklich von allen fremden Inhalten, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.
<br />
            <br />
            <h5 className="padding-10-bottom">Urheberrechte</h5>
            Das Urheberrecht und alle anderen Rechte an den Inhalten, Bildern, Fotos oder sonstigen Dateien auf der Website gehören ausschliesslich dem Betreiber dieser Website oder den namentlich genannten Rechteinhabern. Für die Vervielfältigung sämtlicher Dateien muss vorab die schriftliche Zustimmung der Urheberrechtsinhaber eingeholt werden.<br />
            Wer ohne Zustimmung der jeweiligen Urheberrechtsinhaber eine Urheberrechtsverletzung begeht, kann sich strafbar machen und unter Umständen wegen Schadenersatzansprüchen belangt werden.<br /><br />
            Februar 2024
          </p>
          <br /><br />
        </div>
      </div>

          <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>
      <footer className="mainFooter">
        <div className="wrapper">
          <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
            <ul>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
            </ul>
          </div>
          <div className="footerNotAnotherFlexboxButAGrid linklist">
            <div className="flex-item1">
              <ul className="standardUL">
                <li>
                  <a href="/dokumentationsloesungen/heime/">
                    <strong>Heime</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/spitaeler/">
                    <strong>Spitäler</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/rehabilitation/">
                    <strong>Rehabilitation</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item2">
              <ul className="standardUL">
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -L BESA
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -L RAI-NH
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-b/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -B
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -H
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-r/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -R
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item3">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    WiCare|now tacs<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    WiCare|now LEP<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-le/">
                    WiCare|now LE
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item4">
              <ul>
                <li>
                  <a href="/portrait/">
                    <strong>Über uns</strong>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/lehrlinge/">Lernende</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/partner/">Partner</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                </li>
                <li>
                  <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                </li>
              </ul>
            </div>
            <div className="flex-item5">
              <ul>
                <li>
                  <a href="/support/" className="supportLink">
                    <strong>Support</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="wrapper">
            <div className="column">
              © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
            </div>
            <div className="column has-padding-left">
              <ul>
                <li>
                  <a href="/datenschutz/">Datenschutzerklärung</a>
                </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
              </ul>
            </div>
            <div className="column align-right">
              <a href="/portrait/kontakt/" className="anfahrt">
                <img
                  id="location-pointer"
                  src="/assets/images/icons/icon-anfahrt.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </DefaultLayout>
);

export default Impressum;
